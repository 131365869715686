export const AUTH = "AUTH";
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";
export const FETCH_ALL = "FETCH_ALL";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const ERROR = "ERROR";

export const SCROLL_TO = "SCROLL_TO"
export const CLEAR_SCROLL = "CLEAR_SCROLL"
export const GO_TO_PAGE = "GO_TO_PAGE"
export const GO_TO_PARGRAF = "GO_TO_PARGRAF"
export const CLEAR_PAGE = "CLEAR_PAGE"
export const NOTIFICATION = "NOTIFICATION"

